/* eslint-disable no-param-reassign, no-unused-vars, prettier/prettier */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import logo from "assets/images/logo.png";
import bgImage from "assets/images/servers-1.jpg";
import imgServers2 from "assets/images/servers-2.jpg";
import imgServers3 from "assets/images/servers-3.jpg";
import imgServers4 from "assets/images/servers-4.jpg";
import imgServers5 from "assets/images/servers-5.jpg";
import imgServers6 from "assets/images/servers-6.jpg";
import imgServers7 from "assets/images/servers-7.jpg";

import DefaultNavbar from "../examples/Navbars/DefaultNavbar/simple";

function AboutEn() {
  return (
    <>
      <DefaultNavbar
        brand={
          <>
            <MKBox
              component="img"
              src={logo}
              // alt={title}
              width="3.1rem"
              style={{ float: "left" }}
              sx={({ breakpoints }) => {
                return {
                  [breakpoints.down("md")]: {
                    width: "2.4rem",
                  },
                };
              }}
            />
            <MKTypography
              variant="h3" color="white" mt={1}
              style={{ textShadow: "#051e56 0px 0px 10px" }}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["xl"],
                },
              })}>
              Bishkek Print
            </MKTypography>
          </>
        }
        transparent
        light
      />

      <MKBox
        // minHeight="45vh"
        width="100%"
        sx={{
          minHeight: 260,
          height: "45vh",
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h2"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["2xl"],
                },
              })}
            >
              Инновациялуу Шешимдерди Инфраструктуранын Эффективдүүлүгү үчүн
            </MKTypography>
            <MKTypography variant="h4" color="white" mt={1} mb={3}>
              Сервердик шкафтар жана стойканы
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>

      <div style={{ background: "#0b1f49" }}>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            // mb: 4,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <MKBox component="section" py={5}>
            <Container>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} lg={6}>
                  <MKTypography variant="body1" mt={1} mb={3}>
                    <b>Сервердик шкафтар жана стойканы</b> современдик маалыматтык технологиялардын болгономдук
                    токтомдорун жасоо жана маалыматтык маалыматтарды сактоо жана өңдөө инфраструктурасынын ыңгайлуу жана
                    эффективдүү түрдө түзүлүшүндө күрөөчү рөл ойнотуп, саякатты жана өзүнчө жаран жана жеткиликтүү
                    компоненттерди жайгаштырууга көмөк кылышат.
                  </MKTypography>

                  <MKBox position="relative" borderRadius="lg" align="center">
                    <MKBox
                      component="img"
                      src={imgServers2}
                      // alt={title}
                      // borderRadius="lg"
                      width="65%"
                      position="relative"
                      zIndex={1}
                    />
                  </MKBox>

                  <MKTypography variant="body1" mt={1} mb={3}>
                    <b>Сервердик шкафтар жана стойканы</b> озунун жайгаштыруу жана ис-техникалык инфраструктуранин
                    аралыктоо жана башкаруу үчүн айырмаштуу жасалгандар, адатта техникалык камералардын жайгашкан жерди
                    жана коммутациялоо үчүн түзүлгөн. Алар эмне менен гана техникалык маалыматтык куралдардын физикалык
                    үлгүсүн камтыйт, эмне менен гана вентиляцияны жайгаштырууду, кабельдерди башкарууду жана системаны
                    көбөйтүү мүмкүнчүлүктө жеткиликтүү кылышат.
                  </MKTypography>
                </Grid>

                <Grid item xs={12} lg={6} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
                  <MKBox
                    component="img"
                    src={imgServers3}
                    // alt={title}
                    borderRadius="lg"
                    width="100%"
                    position="relative"
                    zIndex={1}
                  />

                  <MKTypography variant="body1" mt={1} mb={3}>
                    Сервердик стеллаждардын маанилүү компоненти - муздатуу системасы, ал шкафтын ичиндеги температуранын
                    оптималдуу температурада сакталышын камсыз кылат. Бул аварияга жана маалыматтардын жоголушуна алып
                    келиши мүмкүн болгон жабдуулардын ысып кетишинин алдын алуу үчүн өзгөчө маанилүү.
                  </MKTypography>


                  <MKBox
                    component="img"
                    src={imgServers4}
                    // alt={title}
                    borderRadius="lg"
                    width="100%"
                    position="relative"
                    zIndex={1}
                  />

                </Grid>
              </Grid>
            </Container>
          </MKBox>

          <MKBox
            component="section"
            variant="gradient"
            style={{ background: "#0b1f49" }}
            position="relative"
            py={6}
            px={{ xs: 2, lg: 0 }}
            mx={-2}
          >
            <Container>
              <Grid container>
                <Grid item xs={12} sx={{ mb: 6 }}>
                  <MKTypography variant="h3" color="white">
                    Сервердик шкафтар жана стойканын колдонуунун башкача ырастоолору
                  </MKTypography>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <MKBox mb={1}>
                    <MKTypography variant="body1" mt={1} mb={3} color="white">
                      <b>Мекенин түзөтүү:</b> Сервердик шкафтар серверлерди жайгаштуу үчүн компакт жана жанаңылыктуу
                      өлчөмдөрдү берет, бул офис адамдарынын мекен-жайларын ыңгайлуу колдонууга мүмкүнчүлүк берет.
                    </MKTypography>

                    <MKBox
                      component="img"
                      src={imgServers6}
                      // alt={title}
                      borderRadius="lg"
                      width="100%"
                      position="relative"
                      zIndex={1}
                    />

                    <MKTypography variant="body1" mt={1} mb={3} color="white">
                      <b>Маалыматтардын учурдаштыгын камтыйтуу:</b> Сервердик шкафтар маалыматтардын тизмесиден тышкары
                      зайдан кирүүгө ынтымак көрсөтөт жана сактоо маалыматтарынын учурдаштыгын камтыйт.
                    </MKTypography>

                    <MKTypography variant="body1" mt={1} mb={3} color="white">
                      <b>Кабельдерди башкаруу:</b> Стоиканы колдонуу кабель инфраструктурасын жакшы жайгаштырууга көмөк
                      кылышат, бул кабельдерди ачууга жардам берет жана оборудованиянын ыңгайлуу жана жеткиликтүү
                      бакыттыкүлүгүн жагып жатат.
                    </MKTypography>
                  </MKBox>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <MKBox mb={1}>
                    <MKTypography variant="body1" mt={1} mb={3} color="white">
                      <b>Эффективдүү вентиляция жана салбыш:</b> Сервердик шкафтарга жайгашкан вентиляция жана салбыш
                      системалары оборудованиянын стабильдүү жумуш шарттарын камтыйт, оборудованиянын ылдамдуулукту
                      камсыз кылганда, маалыматтардын жеткиликтүүлүгүн жана узатуулукту арттыруу.
                    </MKTypography>
                    <MKTypography variant="body1" mt={1} mb={3} color="white">
                      <b>Масштабтоо мүмкүнчүлүгү:</b> Сервердик стойкалар бизнесдин көбөйтүлүүчү маселелерине караганда
                      IT-инфраструктурун көбөйтүүгө мүмкүнчүлүк берет гибелек архитектура берет.
                    </MKTypography>

                    <MKBox
                      component="img"
                      src={imgServers5}
                      // alt={title}
                      borderRadius="lg"
                      width="100%"
                      position="relative"
                      zIndex={1}
                    />

                    <MKTypography variant="body1" mt={1} mb={3} color="white">
                      <b>Кызматтыктан энчи көрүү:</b> Шкафтар оборудованияга жетүүгө жөндөө берет, бул оборудованиянын
                      бакыттык жана компоненттерди алмаштыруу жана алмашуу процессин көбөйтөт.
                    </MKTypography>
                  </MKBox>
                </Grid>
              </Grid>
            </Container>
          </MKBox>

          <MKBox component="section" py={3}>
            <Container>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MKBox>
                    <MKTypography variant="h4" mt={1} mb={0}>
                      <MKBox
                        component="img"
                        src={imgServers7}
                        // alt={title}
                        width="50%"
                        position="relative"
                        zIndex={1}
                        borderRadius="lg"
                        style={{ float: "left" }}
                        mr={2}
                      />

                      Сапаттуу сервердик шкафтарга жана стеллаждарга инвестиция салуу бул жөн гана чыгаша эмес, бул
                      сиздин IT инфраструктураңыздын узак мөөнөттүү туруктуулугуна жана натыйжалуулугуна стратегиялык
                      инвестиция. Биздин инновациялык чечимдерди тандоо менен сиз маалыматтарды ишенимдүү коргоону жана
                      жабдуулардын туруктуулугун гана эмес, ошондой эле бизнесиңиздин өзгөрүп жаткан муктаждыктарына
                      ыңгайлашуу мүмкүнчүлүгүн аласыз. Биздин сервердик шкафтар кепилденген коопсуздукту, кабелдик
                      инфраструктурадагы тартипти жана жогорку өндүрүмдүүлүктү камсыз кылат. Эртеңки бизнесиңиздин
                      гүлдөшүнө кепилдик берүү үчүн бүгүн туура тандоо жасаңыз.
                    </MKTypography>
                  </MKBox>
                </Grid>
              </Grid>
            </Container>
          </MKBox>

          <hr />

          <MKBox
            component="section"
            variant="gradient"
            // bgColor="yellow"
            // style={{ background: "#f8ebd1" }}
            position="relative"
            pb={1}
            // px={{ xs: 2, lg: 0 }}
            pt={1}
            mx={-2}
            // textAlign="center"
          >
            <Container>
              <MKTypography variant="h4">
                Биздин байланыштар
              </MKTypography>

              <Grid container justifyContent="center">
                <Grid item xs={12} lg={6}>
                  <b>Адрес</b>
                  <br />
                  Аламүдүн р-ну, Константиновка а., Садовая көч., 15
                </Grid>
                <Grid item xs={12} lg={3}>
                  <b>Телефон</b>
                  <br />
                  <a href="tel:+996505362232">+996 (505) 362-232</a>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <b>Email</b>
                  <br />
                  <a href="mailto:a.porsukov@bishkekprint.kg">a.porsukov@bishkekprint.kg</a>
                </Grid>
              </Grid>

            </Container>
          </MKBox>

        </Card>

      </div>

      <MKBox style={{
        background: "#0b1f49",
        width: "100%",
        textAlign: "center",
      }}>

        <MKTypography variant="button" fontWeight="regular" color="white">
          Copyright &copy; {new Date().getFullYear()} Bishkek Print.
        </MKTypography>

      </MKBox>


    </>
  );
}

export default AboutEn;
